export default async function ({ store, redirect, route, $auth }) {
  const securedPaths = ['/maintenance', '/maintenance/']
  if (!securedPaths.includes(route.path)) {
    if (!store.state.maintenance.maintenanceStatus.checked) {
      await store.dispatch('maintenance/getMaintenanceStatus')
    }
    if (store.state.maintenance.maintenanceStatus.data) {
      redirect('/maintenance')
    }
  }
}
