import encryptor from '~/plugins/encryption.js'

export const state = () => ({
  loading: false,
  error: {},
  maintenanceStatus: { value: false, checked: false }
})

export const mutations = {
  // set_MaintenanceStatus(state, data) {
  //   state.maintenanceStatus = data
  // },
  set_MaintenanceStatus(state, value) {
    state.maintenanceStatus = { value, checked: true }
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}
export const actions = {
  async getMaintenanceStatus({ commit }) {
    try {
      // This will stored data for 5mins.
      if (
        sessionStorage.getItem('set_MaintenanceStatus_timezone') &&
        sessionStorage.getItem('set_MaintenanceStatus')
      ) {
        const aa = sessionStorage.getItem('set_MaintenanceStatus_timezone')
        const now = new Date().getTime()
        const distance = now - aa
        const seconds = Math.floor(distance / 1000)
        const minutes = Math.floor(seconds / 60)
        if (minutes >= 5) {
          sessionStorage.removeItem('set_MaintenanceStatus')
          sessionStorage.removeItem('set_MaintenanceStatus_timezone')
        }
      }
      if (process.client) {
        if (sessionStorage.getItem('set_MaintenanceStatus')) {
          const data = await JSON.parse(
            encryptor.decrypt(sessionStorage.getItem('set_MaintenanceStatus'))
          )
          commit('set_MaintenanceStatus', data)
          return
        }
      }

      commit('set_loading', true)
      const response = await this.$axios({
        method: 'GET',
        baseURL: process.env.CONFIG_V2_API_URL,
        url: 'maintenance/getMaintenanceStatus '
      })
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.status
      ) {
        commit('set_MaintenanceStatus', response.data.data.status)
        sessionStorage.setItem(
          'set_MaintenanceStatus',
          encryptor.encrypt(JSON.stringify(response.data.data.status))
        )
      } else {
        commit('set_MaintenanceStatus', false)
        sessionStorage.setItem(
          'set_MaintenanceStatus',
          encryptor.encrypt('false')
        )
      }
      sessionStorage.setItem(
        'set_MaintenanceStatus_timezone',
        new Date().getTime()
      )
      commit('set_loading', false)
    } catch (error) {
      commit('set_MaintenanceStatus', false)
      commit('set_error', error)
    } finally {
      commit('set_loading', false)
    }
  }
}
