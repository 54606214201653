import socket from '@/plugins/socket.client'
export const state = () => ({
  ids: [],
  socketId: undefined
})

export const mutations = {
  RESET_SOCKET_ID(state) {
    state.socketId = undefined
  },
  SET_IDS(state, data) {
    // console.log('joinRooms', data)
    state.ids = data
  },
  SET_SOCKET_ID(state, data) {
    state.socketId = data
  }
}

export const actions = {
  joinRooms({ commit, state }, socketId) {
    try {
      commit('SET_SOCKET_ID', socketId)
      if (state.ids.length > 0) {
        socket.commonSocket.emit('joinRooms', {
          roomNames: state.ids
        })
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = state.ids
      this.commit('socketMarket/socketFailureLog', error, errorData)
    }
  },

  joinRoomsIndividual({ commit, state }, { socketId, gameName, betfairId }) {
    commit('SET_SOCKET_ID', socketId)
    if (gameName) {
      const roomNamesIndividual = []
      if (this.$auth.loggedIn) {
        roomNamesIndividual.push(`ArtemiseSocket_${this.$auth.user._id}`)
        roomNamesIndividual.push(`ArtemiseFancySocket_${this.$auth.user._id}`)
        roomNamesIndividual.push(`BookmakerMarket_${this.$auth.user._id}`)
        roomNamesIndividual.push(
          `ArtemiseSocketAfterPlaceBets_${this.$auth.user._id}`
        )
      }
      try {
        socket.commonSocket.emit('joinRooms', {
          roomNames: roomNamesIndividual
        })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    }
  },
  joinRoomsEvent({ commit, state }, { socketId, gameName, betfairId }) {
    commit('SET_SOCKET_ID', socketId)
    if (gameName) {
      try {
        socket.commonSocket.emit('joinRooms', {
          roomNames: [`AllSocket_Event_${betfairId}`]
        })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    }
  },
  joinRoomsEventEnableDisable(
    { commit, state },
    { socketId, gameName, betfairId }
  ) {
    commit('SET_SOCKET_ID', socketId)
    if (gameName) {
      try {
        socket.commonSocket.emit('joinRooms', {
          roomNames: [`AllSocket_Event_Enable_Disable_${betfairId}`]
        })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    }
  },
  joinRoomsFancy({ commit, state }, { socketId, gameName, betfairId }) {
    commit('SET_SOCKET_ID', socketId)
    if (gameName) {
      try {
        socket.commonSocket.emit('joinRooms', {
          roomNames: [`AllSocket_Event_Fancy_${betfairId}`]
        })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    }
  },
  joinRoomsBookmaker({ commit, state }, { socketId, gameName, betfairId }) {
    commit('SET_SOCKET_ID', socketId)
    if (gameName) {
      try {
        socket.commonSocket.emit('joinRooms', {
          roomNames: [`AllSocket_Event_Bookmaker_${betfairId}`]
        })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    }
  },
  // joinRoomsBookmakerBook({ commit, state }, { socketId }) {
  //   commit('SET_SOCKET_ID', socketId)

  //   try {
  //     socket.commonSocket.emit('joinRooms', {
  //       roomNames: [`BookmakerMarket_${this.$auth.user._id}`]
  //     })
  //   } catch (error) {
  //     const errorData = {}
  //     errorData.roomNames = state.ids
  //     this.commit('socketMain/socketFailureLog', error, errorData)
  //   }
  // },
  leaveRooms({ state, commit }) {
    try {
      if (state.ids.length > 0 && state.socketId) {
        socket.commonSocket.emit('leaveRooms', {
          roomNames: state.ids
        })
        commit('SET_IDS', [])
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = state.ids
      this.commit('socketMarket/socketFailureLog', error, errorData)
    }
  },
  leaveRoomsFancy({ state, commit }, { betfairId }) {
    try {
      const roomNamesIndividual = [`AllSocket_Event_Fancy_${betfairId}`]
      socket.commonSocket.emit('leaveRooms', {
        roomNames: roomNamesIndividual
      })
    } catch (error) {
      const errorData = {}
      errorData.roomNames = betfairId
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  },
  leaveRoomsBookmaker({ state, commit }, { betfairId }) {
    try {
      const roomNamesIndividual = [`AllSocket_Event_Bookmaker_${betfairId}`]
      socket.commonSocket.emit('leaveRooms', {
        roomNames: roomNamesIndividual
      })
    } catch (error) {
      const errorData = {}
      errorData.roomNames = betfairId
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  },
  leaveRoomsIndividual({ state, commit }, { betfairId }) {
    try {
      const roomNamesIndividual = [
        `AllSocket_Event_${betfairId}`,
        `AllSocket_Event_Enable_Disable_${betfairId}`,
        `AllSocket_Event_Bookmaker_${betfairId}`,
        `AllSocket_Event_Fancy_${betfairId}`
      ]
      if (this.$auth.loggedIn) {
        roomNamesIndividual.push(`ArtemiseSocket_${this.$auth.user._id}`)
        roomNamesIndividual.push(`ArtemiseFancySocket_${this.$auth.user._id}`)
        roomNamesIndividual.push(`BookmakerMarket_${this.$auth.user._id}`)
        roomNamesIndividual.push(
          `ArtemiseSocketAfterPlaceBets_${this.$auth.user._id}`
        )
      }
      socket.commonSocket.emit('leaveRooms', {
        roomNames: roomNamesIndividual
      })
    } catch (error) {
      const errorData = {}
      errorData.roomNames = betfairId
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  }
  // leaveRoomsBookmaker({ state, commit }) {
  //   try {
  //     socket.commonSocket.emit('leaveRooms', {
  //       roomNames: [`BookmakerMarket_${this.$auth.user._id}`]
  //     })
  //   } catch (error) {
  //     const errorData = {}
  //     errorData.roomNames = `BookmakerMarket_${this.$auth.user._id}`
  //     this.commit('socketMain/socketFailureLog', error, errorData)
  //   }
  // }
}
