export const state = () => ({
  data: [],
  fancy: [],
  premium: [],
  bookmakerMarket: [],
  lineSession: [],
  matchIds: [],
  bookmakerIds: [],
  openedPanels: [],
  openedPanelsThreeEt: [],
  loading: false,
  configlimits: undefined,
  configlimitsType: undefined,
  getVideoUrlRtmp: undefined,
  error: '',
  minMaxMarket: undefined,
  minMaxMarketShow: undefined,
  minMaxMarketPremium: undefined,
  minMaxMarketBookmaker: undefined,
  minMaxMarketLoading: false,
  resMarketIds: undefined,
  resBookmaker: undefined
})

export const mutations = {
  filter_fancy(state, data) {
    let bookIds = []
    if (data && data.length > 0) {
      bookIds = data.map((item) => item.marketId)
    }
    const fancy = state.fancy
    fancy.map((item) => {
      if (bookIds.includes(item.betfairId)) {
        item.fancySort = 0
      } else {
        item.fancySort = 1
      }
      return item
    })

    state.fancy = fancy.sort((a, b) => {
      if (a.fancySort > b.fancySort) return 1
      if (a.fancySort < b.fancySort) return -1
      return 0
    })
  },
  set_matchIds(state, data) {
    state.matchIds = data
    this.commit('placeBet/set_matchIds', state.matchIds)
  },
  set_data_configlimits(state, data) {
    state.configlimits = data.data
  },
  set_bookmakerIds(state, data) {
    state.bookmakerIds = data
  },
  set_data_liveTv(state, data) {
    state.getVideoUrlRtmp = data
  },
  add_to_matchIds(state, data) {
    state.matchIds = [...state.matchIds, ...data]
    this.commit('placeBet/set_matchIds', state.matchIds)
    this.commit('socketRate/SET_IDS', [state.matchIds])
  },
  reset(state) {
    state.data = []
    state.fancy = []
    state.lineSession = []
    state.premium = []
    state.bookmakerMarket = []
  },
  set_data(state, data) {
    state.openedPanels = [...data, 100].map((m, i) => i)
    state.data = data
  },
  set_line_session(state, data) {
    state.lineSession = data
  },
  set_fancy(state, data) {
    state.fancy = data.filter(
      (item) => item.status === 'Enable' || item.status === 'resultDeclared'
    )
    this.commit('matchInside/filter_fancy', this.state.betBooks.cleanData)
  },
  set_single_fancy(state, data) {
    const fancyData = state.fancy
    const index = fancyData.findIndex((el) => el._id === data._id)
    if (index >= 0) {
      fancyData[index] = data
      state.fancy = fancyData
    }
  },

  filter_bookmaker_market(state, data) {
    let bookIds = []
    if (data && data.length > 0) {
      bookIds = data.map((item) => item.marketUniqueId)
    }
    const bookmakerMarket = state.bookmakerMarket
    bookmakerMarket.map((item) => {
      if (bookIds.includes(item._id)) {
        item.bookmakerMarketSort = 0
      } else {
        item.bookmakerMarketSort = 1
      }
      return item
    })

    state.bookmakerMarket = bookmakerMarket.sort((a, b) => {
      if (a.bookmakerMarketSort > b.bookmakerMarketSort) return 1
      if (a.bookmakerMarketSort < b.bookmakerMarketSort) return -1
      return 0
    })
  },

  filter_premium(state, data) {
    let bookIds = []
    if (data && data.length > 0) {
      bookIds = data.map((item) => item.marketUniqueId)
    }
    const premium = state.premium
    premium.map((item) => {
      if (bookIds.includes(item._id)) {
        item.premiumSort = 0
      } else {
        item.premiumSort = 1
      }
      return item
    })

    state.premium = premium.sort((a, b) => {
      if (a.premiumSort > b.premiumSort) return 1
      if (a.premiumSort < b.premiumSort) return -1
      return 0
    })
  },
  set_premium(state, data) {
    state.premium = data
    this.commit(
      'matchInside/filter_premium',
      this.state.betBooks.clearPremiumData
    )
  },
  set_error(state, data) {
    console.log('set_error', data)
    state.error = data
    this.commit('snackbar/open', {
      text: state.error,
      color: 'error'
    })
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_min_max_market(state, data) {
    state.minMaxMarket = data
  },
  set_min_max_market_show(state, data) {
    state.minMaxMarketShow = data
  },
  set_min_max_market_premium(state, data) {
    state.minMaxMarketPremium = data
  },
  set_min_max_market_bookmaker(state, data) {
    state.minMaxMarketBookmaker = data
  },
  set_min_max_market_loading(state, data) {
    state.minMaxMarketLoading = data
  },
  set_resMarketIds(state, data) {
    state.resMarketIds = data
  },
  set_resBookmaker(state, data) {
    state.resBookmaker = data
  }
}

export const actions = {
  getSocketMarketData(
    { commit },
    { marketId, linesession, status, marketData }
  ) {
    if (linesession && status === 'winnerdeclared') {
      const socketMarketData = marketData.data.filter(function (obj) {
        return obj.betfairId !== marketId
      })
      const socketMarketDataArry = {
        data: socketMarketData
      }
      commit('set_line_session', socketMarketDataArry)
    }
  },
  async getFancy({ commit }, { market }) {
    try {
      let _accessToken
      if (this.$auth.loggedIn) {
        _accessToken = this.$auth.getToken('customLocal')
      }
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.CUSTOMER_SERVICE_URL,
        url: 'SubMarket/getAll',
        data: { market, _accessToken }
      })
      if (Array.isArray(data)) {
        const matchIds = data.map((match) => match.betfairId)

        if (this.$router.currentRoute.params) {
          this.commit('socketCustomerService/SET_IDS_SINGLE', matchIds)
        } else {
          this.commit('socketCustomerService/SET_IDS_SINGLE', [])
        }

        commit('add_to_matchIds', matchIds)
        commit('set_fancy', data)
      } else {
        this.commit('socketCustomerService/SET_IDS_SINGLE', [])
        commit('set_fancy', [])
      }
    } catch (error) {}
  },
  async getAllUOFMarketsByEventId({ commit }, { payload, socketData }) {
    try {
      let data = {}
      if (socketData) {
        commit('set_premium', socketData)
      } else if (!socketData && payload) {
        data = await this.$axios({
          method: 'get',
          baseURL: process.env.UOF_SPORTSRADAR_SERVICE_URL,
          url: `/Market/getAllUOFMarketsByEventId/${payload}`
        })
      } else {
        data = []
      }

      if (data?.data?.success === true) {
        commit('set_premium', data.data.data.outputArray)
      }
    } catch (error) {}
  },
  async getMarketsById(
    { commit, state },
    {
      game,
      parentId,
      loading = true,
      socketData,
      bookmaker = true,
      lineSessionStatus = true,
      martketOnOff,
      gameType,
      gameName,
      betradarId
    }
  ) {
    let userId
    let _accessToken
    // let difference = arrA.filter(x => !arrB.includes(x));
    if (this.$auth.loggedIn) {
      userId = this.$auth.user._id
      _accessToken = this.$auth.getToken('customLocal')
    }

    const getMarketIds = async () => {
      try {
        const reqBody = {
          game,
          ...(gameType === 'UOFGame' && { betradarId, gameName }),
          ...(gameType !== 'UOFGame' && {
            _id: userId,
            _accessToken,
            parentId,
            accessLevel: 'Player'
          })
        }
        const response = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_API_URL,
          url: 'Category/getMarketIds',
          data: reqBody
        })
        return response
      } catch (error) {
        console.log(error)
        const response = {
          data: { value: false }
        }
        return response
      }
    }
    let getCustomMarketAndRunner
    if (!gameType && (game !== 'Cricket' || bookmaker === true)) {
      getCustomMarketAndRunner = async () => {
        try {
          const response = await this.$axios({
            method: 'post',
            baseURL: process.env.SPORTSBOOK_API_URL,
            url: 'category/getCustomMarketAndRunner',
            data: {
              parentId,
              _accessToken
            }
          })
          return response
        } catch (error) {
          console.log(error)
          const response = {
            data: { value: false }
          }
          return response
        }
      }
    } else {
      getCustomMarketAndRunner = () => {
        const response = {
          data: { value: false }
        }
        return response
      }
    }
    let lineSession
    // eslint-disable-next-line prefer-const
    lineSession = () => {
      const response = {
        data: { value: false }
      }
      return response
    }
    // console.log(lineSessionStatus)
    if (
      !gameType &&
      lineSessionStatus &&
      process.env.LINE_SESSSION_URL &&
      gameName !== 'UOFGame'
    ) {
      lineSession = async () => {
        // lineSession = () => {
        try {
          // const response = {
          //   data: { value: false }
          // }
          // return response
          const response = await this.$axios({
            method: 'post',
            baseURL: process.env.LINE_SESSSION_URL,
            url: 'linesession/getlinesession',
            data: {
              _accessToken,
              eventId: parentId
            }
          })
          return response.data
        } catch (error) {
          const response = {
            data: { value: false }
          }
          return response
        }
      }
    } else {
      lineSession = () => {
        const response = {
          data: { value: false }
        }
        return response
      }
    }
    try {
      commit('set_loading', loading)
      if (
        socketData &&
        socketData !== 'No data found' &&
        socketData.length > 0
      ) {
        const lineSessionData = socketData.filter(
          (v) =>
            v?.level?.includes('betfair-line-sessions') &&
            !v?.betfairId?.includes('#bookmaker')
        )
        const marketIdsData = socketData.filter(
          (v) =>
            !v?.betfairId?.includes('#bookmaker') &&
            !v?.level?.includes('betfair-line-sessions')
        )
        if (
          marketIdsData &&
          marketIdsData.length > 0 &&
          (lineSessionData.length === 0 || !lineSessionData)
        ) {
          const data = {
            data: {
              value: true,
              data: marketIdsData
            }
          }
          commit('set_resMarketIds', data)
        }
        const bookmakerData = socketData.filter((v) =>
          v?.betfairId?.includes('#bookmaker')
        )
        if (bookmakerData && bookmakerData.length > 0) {
          let data = {
            data: {
              value: true,
              data: bookmakerData
            }
          }
          if (game === 'Cricket' && bookmaker === false) {
            data = {
              data: {
                value: false,
                data: ''
              }
            }
          }
          commit('set_resBookmaker', data)
        }
        if (
          lineSessionStatus &&
          lineSessionData &&
          lineSessionData.length > 0
        ) {
          const data = {
            data: {
              value: true,
              data: lineSessionData
            }
          }
          // console.log('lineSessionData inside', data.data)
          commit('set_line_session', data.data)
        }
      } else {
        const [resMarketIds, resBookmaker, reslineSession] = await Promise.all([
          getMarketIds(),
          getCustomMarketAndRunner(),
          lineSession()
        ])
        commit('set_resMarketIds', resMarketIds)
        commit('set_resBookmaker', resBookmaker)
        commit('set_line_session', reslineSession)
      }
      let response1
      response1 = state.resMarketIds
      const response2 = state.resBookmaker
      const response3 = state.lineSession
      if (
        response1 &&
        response1.data &&
        response1.data.value &&
        response1.data.data.length > 0 &&
        martketOnOff &&
        martketOnOff.length
      ) {
        const difference = response1.data.data.filter(
          (page1) => !martketOnOff.find((page2) => page1.name === page2.name)
        )
        response1 = {
          data: {
            data: difference,
            value: true
          }
        }
      }
      // const response2 = state.resBookmaker

      let data1 = []
      let matchIds1 = []
      if (
        response1?.data?.value &&
        Array.isArray(response1.data.data) &&
        response1?.data?.data?.length > 0
      ) {
        data1 = response1?.data?.data?.map((v) => {
          if (v?.betfairId?.includes('#bookmaker')) {
            v.bookMakerCustom = true
          }
          return v
        })
        matchIds1 = response1?.data?.data?.map((match) => match.betfairId)
        for (let i = 0; i < data1.length; i++) {
          if (data1.length > 0) {
            if (data1[i]?.eventType?.name === 'Soccer') {
              data1.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
            }
          }
        }
      } else if (!response1.data.value) {
        commit('set_error', response1.data.error)
      }

      let data2 = []
      let matchIds2 = []
      if (
        response2 &&
        response2.data &&
        response2.data.value &&
        Array.isArray(response2.data.data) &&
        response2.data.data.length > 0
      ) {
        data2 = response2.data.data
        matchIds2 = response2.data.data.map((match) => match.betfairId)
      }
      let matchIds3 = []

      if (
        response3 &&
        response3.data &&
        Array.isArray(response3.data) &&
        response3.data.length > 0
      ) {
        matchIds3 = response3.data.map((match) => match.betfairId)
      }

      const marketData = [...data1, ...data2]

      // setting match ids for socket on
      if (matchIds1.length > 0 || matchIds3.length > 0) {
        this.commit('socketRate/SET_IDS', [...matchIds1, ...matchIds3])
      }

      this.commit('socketBookmaker/SET_IDS', [...matchIds1, ...matchIds2])
      this.commit('socketRate/SET_IDS', [
        ...matchIds1,
        ...matchIds2,
        ...matchIds3
      ])

      commit('set_matchIds', [...matchIds1, ...matchIds2, ...matchIds3])
      commit('set_data', marketData)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getConfigMaxLimits({ commit }, { game, loading = true }) {
    let _accessToken
    if (this.$auth.loggedIn) {
      _accessToken = this.$auth.getToken('customLocal')
    }
    try {
      const response1 = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'eventTypeMaxLImit/getConfigMaxLimits',
        data: {
          game,
          _accessToken
        }
      })
      response1.data.data.typeConfigLimits = 'api'
      commit('set_data_configlimits', response1.data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.response)
    }
  },
  async getMinMaxMarket(
    { commit },
    {
      game,
      loading = true,
      marketId,
      oddsSelected,
      selectionId,
      position,
      type,
      bettingType,
      threeETId,
      allmaxbets = false,
      name
    }
  ) {
    let _accessToken
    if (this.$auth.loggedIn) {
      _accessToken = this.$auth.getToken('customLocal')
    }
    let baseurl = process.env.SPORTSBOOK_API_URL
    if (bettingType === 'lineLadder') {
      baseurl = process.env.LINE_SESSSION_URL
    }
    try {
      commit('set_min_max_market_loading', loading)
      const response = await this.$axios({
        method: 'post',
        baseURL: baseurl,
        url: '/category/getMinMaxMarket',
        data: {
          game,
          marketId,
          oddsSelected,
          selectionId,
          position,
          type,
          allmaxbets,
          threeETId,
          _accessToken
        }
      })
      if (response && response.data && response.data.value) {
        commit('set_min_max_market', response.data.data)
        if (
          marketId &&
          !marketId.includes('#') &&
          !marketId.includes('-') &&
          name &&
          name === 'Match Odds'
        ) {
          commit('set_min_max_market_show', response.data.data)
        }
        commit('set_min_max_market_loading', false)
      } else {
        commit('set_min_max_market', response.data.data)
        throw response.data
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      if (error && error.data && !allmaxbets) {
        commit('set_min_max_market', error.data)
        commit('set_error', error.data)
      } else if (error && error.message && !allmaxbets) {
        commit('set_min_max_market', error.message)
        commit('set_error', error.message)
      } else {
        // eslint-disable-next-line no-console
        commit('set_min_max_market', 'Network Error')
        console.log('error.....................', error)
      }
      commit('set_min_max_market_loading', false)
    }
  },
  async getMinMaxMarketPremium({ commit }, { marketId, loading }) {
    try {
      commit('set_min_max_market_loading', loading)
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.UOF_SPORTSRADAR_SERVICE_URL,
        url: '/Market/getMinMaxMarketForPlayer/Cricket/' + marketId
      })
      // console.log(response.data.data.outputObject)
      if (response && response.data && response.data && response.data.data) {
        commit('set_min_max_market_premium', response.data.data.outputObject)
        commit('set_min_max_market_loading', false)
      } else {
        commit('set_min_max_market_premium', response.data.data)
        throw response.data
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      commit('set_min_max_market_loading', false)
    }
  },

  async getMinMaxMarketBookmaker(
    { commit },
    { marketId, oddsSelected, type, loading }
  ) {
    try {
      commit('set_min_max_market_loading', loading)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PREMIUM_BOOKMAKER_URL,
        url: '/markets/getMinMaxMarket',
        data: {
          marketUniqueId: marketId.toString(),
          betData: {
            odds: oddsSelected,
            type: type.toUpperCase()
          }
        }
      })
      if (response?.data?.data) {
        commit('set_min_max_market_bookmaker', response.data.data)
        commit('set_min_max_market_loading', false)
      } else {
        commit('set_min_max_market_bookmaker', response.data.data)
        throw response.data
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      commit('set_min_max_market_loading', false)
    }
  },
  async getMinMaxFancyMarket({ commit }, { marketId, oddsSelected, type, loading }) {
    try {
      commit('set_min_max_market_loading', loading)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PREMIUM_BOOKMAKER_URL,
        url: '/markets/getMinMaxFancyMarket',
        data: {
          marketUniqueId: marketId.toString(),
          betData: {
            odds: oddsSelected,
            type: type.toUpperCase()
          }
        }
      })
      if (response?.data?.data) {
        commit('set_min_max_market_bookmaker', response.data.data)
        commit('set_min_max_market_loading', false)
      } else {
        commit('set_min_max_market_bookmaker', response.data.data)
        throw response.data
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      commit('set_min_max_market_loading', false)
    }
  },
  async getVideoUrlRtmp({ commit }, { channel, loading = false }) {
    try {
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'category/getVideoStreamUrl',
        data: {
          // clientIp,
          channel
        }
      })
      commit('set_data_liveTv', response.data.data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
  async getMarketIds({ commit, state }, { game, parentId, martketOnOff }) {
    let userId, _accessToken
    let response = {
      data: { value: false }
    }
    if (this.$auth.loggedIn) {
      userId = this.$auth.user._id
      _accessToken = this.$auth.getToken('customLocal')
    }
    try {
      response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'Category/getMarketIds',
        data: {
          game,
          _id: userId,
          _accessToken,
          parentId,
          accessLevel: 'Player'
        }
      })
    } catch (error) {
      response = {
        data: { value: false }
      }
      console.log('getMarketIds', error)
    }
    commit('set_resMarketIds', response)
    try {
      let resMarketIdsData = state.resMarketIds
      if (
        resMarketIdsData?.data?.value &&
        Array.isArray(resMarketIdsData.data.data) &&
        resMarketIdsData.data.data.length > 0 &&
        martketOnOff?.length > 0
      ) {
        const difference = resMarketIdsData.data.data.filter(
          (page1) => !martketOnOff.find((page2) => page1.name === page2.name)
        )
        resMarketIdsData = {
          data: {
            data: difference,
            value: true
          }
        }
      } else if (!resMarketIdsData.data.value) {
        commit('set_error', resMarketIdsData.data.error)
      }
      let data1 = []
      let matchIds1 = []
      if (
        resMarketIdsData.data.value &&
        Array.isArray(resMarketIdsData.data.data) &&
        resMarketIdsData.data.data.length > 0
      ) {
        data1 = resMarketIdsData?.data?.data?.map((v) => {
          if (v?.betfairId?.includes('#bookmaker')) {
            v.bookMakerCustom = true
          }
          return v
        })
        matchIds1 = data1.map((match) => match.betfairId)
        if (data1.some((item) => item.eventType.name === 'Soccer')) {
          data1.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
        }
      }
      const marketData = [...data1]
      commit('set_data', marketData)
      commit('set_matchIds', [...matchIds1])
      commit('set_bookmakerIds', [...matchIds1])
      commit('set_loading', false)
      this.commit('socketBookmaker/SET_IDS', [...matchIds1])
      this.commit('socketRate/SET_IDS', [...matchIds1])
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getCustomMarketAndRunner({ commit, state }, { parentId }) {
    let _accessToken
    let response = {
      data: { value: false }
    }
    this.$auth.loggedIn && (_accessToken = this.$auth.getToken('customLocal'))
    try {
      response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'Category/getCustomMarketAndRunner',
        data: {
          parentId,
          _accessToken
        }
      })
    } catch (error) {
      response = {
        data: { value: false }
      }
      console.log('getCustomMarketAndRunner', error)
    }
    commit('set_resBookmaker', response)
    try {
      const resBookmakerData = state.resBookmaker
      let bookmakerData = []
      let bookmakerIds = []

      if (
        resBookmakerData &&
        resBookmakerData.data &&
        resBookmakerData.data.value &&
        Array.isArray(resBookmakerData.data.data) &&
        resBookmakerData.data.data.length > 0
      ) {
        bookmakerData = resBookmakerData.data.data
        bookmakerIds = bookmakerData.map((match) => match.betfairId)
      }

      const marketData = [...state.data, ...bookmakerData]
      commit('set_data', marketData)
      commit('set_matchIds', [...state.matchIds, ...bookmakerIds])
      commit('set_bookmakerIds', [...state.bookmakerIds, ...bookmakerIds])
      commit('set_loading', false)
      this.commit('socketBookmaker/SET_IDS', [...state.bookmakerIds])
      this.commit('socketRate/SET_IDS', [...state.matchIds, ...bookmakerIds])
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getLineSession({ commit, state }, { parentId }) {
    let _accessToken
    let response = {
      data: { value: false }
    }
    this.$auth.loggedIn && (_accessToken = this.$auth.getToken('customLocal'))
    try {
      response = await this.$axios({
        method: 'post',
        baseURL: process.env.LINE_SESSSION_URL,
        url: 'linesession/getlinesession',
        data: {
          _accessToken,
          eventId: parentId
        }
      })
    } catch (error) {
      response = {
        data: { value: false }
      }
      console.log('getLineSession', error)
    }
    commit('set_line_session', response.data)
    try {
      const resLineSessionData = state.lineSession
      let lineSessionIds = []
      if (
        resLineSessionData &&
        resLineSessionData.data &&
        Array.isArray(resLineSessionData.data) &&
        resLineSessionData.data.length > 0
      ) {
        lineSessionIds = resLineSessionData.data.map((match) => match.betfairId)
      }
      commit('set_matchIds', [...state.matchIds, ...lineSessionIds])
      commit('set_loading', false)
      this.commit('socketRate/SET_IDS', [...state.matchIds, ...lineSessionIds])
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  getMarketSocketData({ commit, state }, { socketData, martketOnOff }) {
    try {
      commit('set_loading', false)
      const lineSessionData = socketData.filter(
        (v) =>
          v.level.includes('betfair-line-sessions') &&
          !v.betfairId.includes('#bookmaker')
      )
      const marketIdsData = socketData.filter(
        (v) =>
          !v.betfairId.includes('#bookmaker') &&
          !v.level.includes('betfair-line-sessions')
      )
      const bookmakerData = socketData.filter((v) =>
        v.betfairId.includes('#bookmaker')
      )

      if (
        marketIdsData.length > 0 &&
        (lineSessionData.length === 0 || !lineSessionData)
      ) {
        commit('set_resMarketIds', {
          data: { value: true, data: marketIdsData }
        })
      }

      if (bookmakerData && bookmakerData.length > 0) {
        const data = {
          data: {
            value: bookmakerData && bookmakerData.length > 0,
            data: bookmakerData && bookmakerData.length > 0 ? bookmakerData : ''
          }
        }
        commit('set_resBookmaker', data)
      }

      if (lineSessionData.length > 0) {
        const socketMarketDataArry = {
          value: true,
          data: lineSessionData
        }
        commit('set_line_session', socketMarketDataArry)
      }

      const response1 = state.resMarketIds
      const response2 = state.resBookmaker
      const response3 = state.lineSession

      // Check if response1 is valid and contains data
      if (
        response1 &&
        response1.data &&
        response1.data.value &&
        response1.data.data.length > 0 &&
        martketOnOff &&
        martketOnOff.length
      ) {
        const difference = response1.data.data.filter(
          (page1) => !martketOnOff.find((page2) => page1.name === page2.name)
        )

        response1.data.data = difference
      }

      // Process response1 data
      let data1 = []
      let matchIds1 = []

      if (
        response1?.data?.value &&
        Array.isArray(response1?.data?.data) &&
        response1.data.data.length > 0
      ) {
        data1 = response1?.data?.data.map((v) => {
          if (v?.betfairId?.includes('#bookmaker')) {
            v.bookMakerCustom = true
          }
          return v
        })

        matchIds1 = data1.map((match) => match.betfairId)

        if (data1.length > 0 && data1[0].eventType.name === 'Soccer') {
          data1.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
        }
      } else if (!response1.data.value) {
        commit('set_error', response1.data.error)
      }

      // Process response2 data
      let data2 = []
      let matchIds2 = []

      if (
        response2 &&
        response2.data &&
        response2.data.value &&
        Array.isArray(response2.data.data)
      ) {
        data2 = response2.data.data
        matchIds2 = data2.map((match) => match.betfairId)
      }

      // Process response3 data
      let matchIds3 = []

      if (response3 && Array.isArray(response3.data)) {
        matchIds3 = response3.data.map((match) => match.betfairId)
      }

      // Combine data from response1 and response2
      const marketData = [...data1, ...data2]

      // Setting match IDs for sockets
      const allMatchIds = [...matchIds1, ...matchIds2, ...matchIds3]
      this.commit('socketRate/SET_IDS', allMatchIds)
      this.commit('socketBookmaker/SET_IDS', [...matchIds1, ...matchIds2])
      commit('set_matchIds', allMatchIds)
      commit('set_data', marketData)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
